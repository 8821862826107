import React from 'react'
import Footer from '../../components/footer'
import '../../styles/virtual-edge.less'
import SEO from '../../components/SEO'
import Header from '../../components/header'
import Drop from '../../components/drop'
const loadText = require('src/utils').loadText
import { ConsultationPopButton } from '../../components/form-biz'
const static_data = loadText('virtual-edge')

const TopBanner = () => {
  const data = static_data.topBanner
  return (
    <div className="topBanner">
      <div className="bg">
        <div className="headerContainer">
          <Header logo="/logo-blue.svg" />
        </div>
        <div className="content content-center">
          <div className="left">
            <div className="title">{data.title}</div>
            <div className="description">{data.description}</div>
            <ConsultationPopButton
              buttonWidth={348}
              source="zenlayer-virtual-edge"
            >
              {data.btnText}
            </ConsultationPopButton>
          </div>
          <img src="/products/virtual-edge/topBanner-pic.svg" alt="" />
        </div>
      </div>
    </div>
  )
}
const B1 = () => {
  const data = static_data.b1
  const bgColors = [
    '#E1F5FF',
    'linear-gradient(90deg, #FFF9E1 0%, #FFF5CF 100%)',
    'linear-gradient(180deg, #F2FDFF 0%, #E0F9FF 100%)',
    'linear-gradient(180deg, #FAF4FF 0%, #F4E6FF 100%) ',
  ]
  return (
    <div className="b1">
      <div className="title">{data.title}</div>
      <div className="content content-center">
        {data.content.map((text, idx) => (
          <div className="item" style={{ background: bgColors[idx] }}>
            <img src={`/products/virtual-edge/b1-icon${idx + 1}.svg`} alt="" />
            <div className="text">{text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
const B2 = () => {
  const data = static_data.b2
  return (
    <div className="b2">
      <div className="content content-center">
        <div className="title">{data.title}</div>
        <div className="pic-container">
          <img src="/products/virtual-edge/b2-pic.svg" alt="" />
        </div>
      </div>
    </div>
  )
}
const B3 = () => {
  const data = static_data.b3
  const Item = ({ title, description, pic, className }) => {
    return (
      <div className={`item ${className ? className : ''}`}>
        <div className="content" style={{ position: 'relative' }}>
          <Drop color="#f5f9ff" />

          <div className="title">{title}</div>
          {description.map((text) => (
            <div>{text}</div>
          ))}
        </div>
        <img src={pic} alt="" />
      </div>
    )
  }
  return (
    <div className="b3">
      <div className="content">
        <div className="title">{data.title}</div>
        <div className="item-wrapper">
          {data.content.map(({ title, description }, idx) => (
            <>
              <Item
                title={title}
                description={description}
                pic={`/products/virtual-edge/b3-pic${idx + 1}.jpg`}
                className={idx === 1 ? 'reverse' : ''}
              />
              {idx === 0 && (
                <div className="plus-wrapper">
                  <div className="plus">+</div>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="bottom content-center">
          <div className="title">{static_data.topBanner.title}</div>
        </div>
      </div>
    </div>
  )
}
const B4 = () => {
  const data = static_data.b4
  return (
    <div className="b4">
      <div className="bg">
        <div className="title">{data.title}</div>
        <div className="content content-center">
          {data.content.map(({ title, description }, idx) => (
            <div className="item">
              <img
                src={`/products/virtual-edge/b4-icon${idx + 1}.png`}
                alt=""
              />
              <div className="title">{title}</div>
              <div className="description">{description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const B5 = () => {
  const data = static_data.b5
  return (
    <div className="b5">
      <div className="title">{data.title}</div>
      <div className="content content-center">
        {data.content.map((title, idx) => (
          <div className="item">
            <img src={`/products/virtual-edge/b5-icon${idx + 1}.png`} alt="" />
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
const B6 = () => {
  const data = static_data.b6
  return (
    <div className="b6">
      <div className="title">{data.title}</div>
      <div className="content content-center">
        {data.content.map((title, idx) => (
          <div className="item">
            <img src={`/products/virtual-edge/b6-icon${idx + 1}.png`} alt="" />
            <div className="title">{title}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
const B7 = () => {
  const data = static_data.b7
  const Item = ({
    name,
    description,
    problems,
    solutions,
    bg,
    pic1,
    pic2,
    className,
    bgM,
  }) => (
    <div className={`item content-center ${className ? className : ''}`}>
      <div className="item-bg">
        <img src={bg} alt="" className="only-desktop" />
        <img src={bgM} alt="" className="only-mobile" />
        <div>{name}</div>
      </div>
      <div className="left">
        <div className="description">{description}</div>
        <div className="pic1-wrapper">
          <img src={pic1} alt="" />
        </div>
      </div>
      <div className="right">
        <div className="label">
          <span>{data.problem}</span>
        </div>
        <ul>
          {problems.map((p) => (
            <li>{p}</li>
          ))}
        </ul>
        <div className="pic2-wrapper">
          <img src={pic2} alt="" />
        </div>
        <div className="label">
          <span>{data.solution}</span>
        </div>
        <ul>
          {solutions.map((p) => (
            <li>{p}</li>
          ))}
        </ul>
      </div>
    </div>
  )
  return (
    <div className="b7">
      <Drop color="rgb(246,250,254)" />
      <div className="bg">
        <div className="title">{data.title}</div>
        {data.content.map((props, idx) => (
          <Item
            {...props}
            bg={`/products/virtual-edge/b7-case${idx + 1}-bg.jpg`}
            bgM={`/products/virtual-edge/b7-case${idx + 1}-bg-m.jpg`}
            pic1={`/products/virtual-edge/b7-case${idx + 1}-pic1.svg`}
            pic2={`/products/virtual-edge/b7-case${idx + 1}-pic2.svg`}
            className={idx === 1 ? 'reverse' : ''}
          />
        ))}
      </div>
    </div>
  )
}
export default function Home() {
  return (
    <div className="virtualEdge">
      <SEO
        {...static_data.seo}
        featuredImage="/featuredImage/virtual-edge.jpg"
      />
      <TopBanner />
      <B1 />
      <B2 />
      <B3 />
      <B4 />
      <B5 />
      <B6 />
      <B7 />
      <Footer />
    </div>
  )
}
